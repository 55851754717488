import React from "react";
import { useHistory } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
const Component = ({ display , message, close}) => {
    const history = useHistory();
    const handleClose = () => {
      close()
    }

    return (
      <Modal show={display} onHide={handleClose} >
      <Modal.Header closeButton style={{border:'0px'}}>
        <Modal.Title></Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p style={{textAlign:'center'}}>{message}</p>
      </Modal.Body>
      <Modal.Footer  style={{border:'0px'}}>
      

          <button class="btn " style={{background: "#384F95",
    width: "99px",
    padding: "5px"}}   onClick={handleClose}>OK </button>
          
        </Modal.Footer>
  
    </Modal>
    );
}

export default Component;